<template>
  <div>
    <el-card>
      <card-header-block></card-header-block>
    </el-card>

    <el-card
      v-if="$canAndRulesPass(card, $permissions.CARD_STAGE.SEE)"
      style="margin-top: 20px"
    >
      <card-stages-block
      ></card-stages-block>
    </el-card>



    <el-card style="margin-top: 20px;">
      <component
        ref="sections-block-header"
        :is="sectionsBlockHeaderTemplate"
      ></component>

      <card-sections
        ref="card_sections"
        @elements-changed="loadOnElementsChange"
      ></card-sections>

      <component
        ref="sections-block-footer"
        :is="sectionsBlockFooterTemplate"
      ></component>

      <card-errors
        v-if="$canAndRulesPass(card, 'card.see_errors')"
        ref="cardErrors"
        :card="card"
      ></card-errors>

    </el-card>

    <el-card
      v-if="card.id && $canAndRulesPass(card, $permissions.COMMENT.SEE)"
      style="margin-top: 20px"
    >
      <div style="font-size: 24px">Комментарии к карте</div>
      <comments-block
        :disabled="!$canAndRulesPass(card, $permissions.COMMENT.CREATE)"
        :entity-type="$constants.comment.entityTypes.TYPE_CARD"
        :entity-id="card.id"
        :send-button-text="$companyConfiguration.i18n.t('card', 'comment_send_button_text', 'Отправить')"
      ></comments-block>
    </el-card>




    <agree-buttons
    ></agree-buttons>

  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import CardStagesBlock from "@/components/cards/CardStagesBlock/CardStagesBlock";
import AgreeButtons from "@/components/cards/AgreeButtons";
import CardErrors from "@/components/cards/CardErrors";
import CommentsBlock from "@/components/comments/CommentsBlock";
import {mapGetters} from "vuex";
import CardSections from "@/components/cards/CardElementsBlock/CardSections";
import HistoryModal from "@/components/HistoryModal";
import CardHeaderBlock from "@/components/cards/CardHeader/CardHeaderBlock";
import CorporateTargetsModal from "@/componentsCompany/beeline/CorporateTargetsModal";
import StartDynamicStageButton from "@/components/cards/StartDynamicStageButton";
import CloneElementsFromCardModal from "@/components/cards/CloneElementsFromCardModal.vue";
import {downloader} from "@/mixins/downloader";

export default {
  name: 'Card',
  components: {
    CloneElementsFromCardModal,
    StartDynamicStageButton,
    CorporateTargetsModal,
    CardHeaderBlock,
    HistoryModal, CardSections, CommentsBlock, CardErrors, AgreeButtons, CardStagesBlock, PageHeading},

  computed: {
    ...mapGetters('card', {
      card:'card'
    }),
    ...mapGetters('card/stages', {
      stages:'stages',
      currentStage: 'currentStage'
    }),
    sectionsBlockHeaderTemplate() {
      return this.$companyConfiguration.cardSections.getSectionsBlockHeaderTemplate();
    },
    sectionsBlockFooterTemplate() {
      return this.$companyConfiguration.cardSections.getSectionsBlockFooterTemplate();
    },
  },
  data() {
    return {

    }
  },
  mounted() {
    this.reloadCard()
  },
  beforeDestroy() {
    this.$store.dispatch('card/unloadCard');
  },
  methods: {
    loadOnElementsChange(){
      this.$store.dispatch('card/errors/loadErrors');
      this.$store.dispatch('card/loadCardData');
    },

    reloadCard(){
      this.$store.dispatch('card/loadCard', { id: parseInt(this.$route.params.id) });
    },
  }
}

</script>


<style scoped lang="scss">
@import "./src/assets/css/project-variables";


</style>
