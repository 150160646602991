export default {
    "available_periods": {
        "1": "Месяц",
        "3": "Квартал",
        "6": "Полугодие",
        "12": "Год"
    },
    "available_sections_for_period": {
        "1": {
            "library": {
                "selected": false
            },
            "library_manual_fact": {
                "selected": false
            },
            "manual": {
                "selected": false
            },
            "multiplier": {
                "selected": false
            }
        },
        "3": {
            "library": {
                "selected": false
            },
            "library_manual_fact": {
                "selected": false
            },
            "manual": {
                "selected": false
            },
            "multiplier": {
                "selected": false
            }
        },
        "6": {
            "library": {
                "selected": false
            },
            "library_manual_fact": {
                "selected": false
            },
            "manual": {
                "selected": false
            },
            "multiplier": {
                "selected": false
            }
        },
        "12": {
            "library": {
                "selected": false
            },
            "library_manual_fact": {
                "selected": false
            },
            "manual": {
                "selected": false
            },
            "multiplier": {
                "selected": false
            }
        }
    },
    "card_limit_for_user_per_period": 0,
    "cards_create_info_template": null,
    "medium_facts": {
        "available": false
    },
    "card_recount_bonus_service": null,
    "card_ar_history_behavior_ignore_fields": [],
    "cards_visible_permissions_criteria_map": {
        "card.see_if_participate": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardIParticipateCriteria",
        "card.see_my": "app\\domain\\Card\\criteria\\CardMyCriteria",
        "card.see_subordinates": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardAllSubordinatesCriteria",
        "card.see_chiefs": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardMyChiefsCriteria"
    },
    "cards_search_criteria": [
        "app\\domain\\Card\\criteria\\CardsUserFiredCriteria"
    ],
    "cards_page_filters": {
        "template": "componentsCompany/astra/CardsPageFilters",
        "default_per_page": 25,
        "search_fields": {
            "cards.id": {
                "condition": "=",
                "type": "number"
            },
            "users.persnum": {
                "relation": "user"
            },
            "users.last_name": {
                "relation": "user"
            },
            "users.first_name": {
                "relation": "user"
            },
            "users.middle_name": {
                "relation": "user"
            },
            "users.position": {
                "relation": "user"
            },
            "users.subdivision": {
                "relation": "user"
            }
        },
        "default_sort_params": {
            "attributes": {
                "id": {
                    "asc": {
                        "cards.id": 4
                    },
                    "desc": {
                        "cards.id": 3
                    }
                },
                "0": "year",
                "1": "bonus",
                "user.persnum": {
                    "asc": {
                        "users.persnum": 4
                    },
                    "desc": {
                        "users.persnum": 3
                    }
                },
                "user.last_name": {
                    "asc": {
                        "users.last_name": 4
                    },
                    "desc": {
                        "users.last_name": 3
                    }
                }
            },
            "defaultOrder": {
                "id": 3
            }
        }
    },
    "cards_page_columns": [
        {
            "label": "Карта №",
            "prop": "id",
            "width": 140
        },
        {
            "label": "Перс. №",
            "prop": "user.persnum"
        },
        {
            "label": "ФИО",
            "template": "components/tableColumnTemplates/cards/RouterLinkToCardColumnTemplate",
            "prop": "user.last_name"
        },
        {
            "label": "Должность",
            "prop": "user.position"
        },
        {
            "label": "Год",
            "prop": "year"
        },
        {
            "label": "Период",
            "template": "components/tableColumnTemplates/cards/PeriodWithIndexColumnTemplate"
        },
        {
            "label": "Текущий этап",
            "template": "components/tableColumnTemplates/cards/CurrentStageColumnTemplate",
            "prop": "currentStage.type"
        },
        {
            "label": "Текущий шаг",
            "template": "components/tableColumnTemplates/cards/CurrentNegotiationColumnTemplate"
        },
        {
            "label": "% премии",
            "prop": "bonus"
        },
        {
            "template": "components/tableColumnTemplates/cards/ActionsColumnTemplate",
            "width": 80,
            "align": "right",
            "on": {
                "delete-card": "(e) => { templateData.$emit(\"delete-card\", e) }"
            }
        }
    ],
    "card_header_elements": [
        {
            "label": "Сотрудник",
            "icon": "fas fa-id-card-alt",
            "prop": "user.full_name"
        },
        {
            "label": "Перс. №",
            "icon": "fas fa-list-ol",
            "prop": "user.persnum"
        },
        {
            "label": "Должность",
            "icon": "fas fa-briefcase",
            "prop": "info.position"
        },
        {
            "label": "Подразделение",
            "icon": "fas fa-building",
            "prop": "info.subdivision"
        },
        {
            "label": "Непосредственный руководитель",
            "icon": "fas fa-user-tie",
            "prop": "user.chiefs.direct.full_name"
        },
        {
            "label": "Функциональный руководитель",
            "icon": "fas fa-user-check",
            "prop": "user.chiefs.functional.full_name"
        }
    ],
    "card_selectable_page_columns": []
}