export default {
    "sectionsBlockHeaderTemplate": "componentsCompany/astra/CardSectionsBlockHeader",
    "sectionsBlockFooterTemplate": "componentsCompany/astra/CardSectionsBlockFooter",
    "sections": {
        "library": {
            "showName": "Библиотечные цели",
            "description": "",
            "emptyText": "Нет библиотечных целей",
            "fromLibrary": "library",
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        },
        "library_manual_fact": {
            "showName": "Библиотечные цели. Ручной факт",
            "emptyText": "Нет библиотечных целей",
            "fromLibrary": "library_manual_fact",
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        },
        "manual": {
            "showName": "Личные цели",
            "addElementButtonText": "Создать цель",
            "emptyText": "Нет личных целей",
            "fieldsAvailableForEdit": {
                "name": {
                    "stages": [
                        "plan"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan"
                    ]
                },
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.template_id": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.scopes": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.unit_id": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        },
        "multiplier": {
            "showName": "Коэффициенты",
            "addElementButtonText": "Добавить коэффициент",
            "emptyText": "Нет коэффициентов",
            "multiplyElementsBonus": true,
            "columnsConfig": "multiplier_columns",
            "fromLibrary": "multiplier",
            "fieldsAvailableForEdit": {
                "comment.plan": {
                    "stages": [
                        "plan"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        }
    },
    "default_columns": [
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Вес",
            "width": 70,
            "template": "components/tableColumnTemplates/card/ElementWeightTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Шкала",
            "width": 340,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScaleTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Единица измерения",
            "width": 90,
            "template": "components/tableColumnTemplates/card/ElementLibraryUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "План",
            "width": 140,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScalePlanTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к плану",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Факт",
            "width": 240,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScaleFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к факту",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['fact']",
                "comments-count": "scope.row.comments_count_by_stage['fact']"
            }
        },
        {
            "label": "% премии",
            "prop": "bonus",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "width": 100
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ],
    "multiplier_columns": [
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Шкала",
            "width": 340,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScaleTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Единица измерения",
            "width": 90,
            "template": "components/tableColumnTemplates/card/ElementLibraryUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "План",
            "width": 140,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScalePlanTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к плану",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Факт",
            "width": 240,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/configurableScales/tableColumnTemplates/ElementConfigurableScaleFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к факту",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['fact']",
                "comments-count": "scope.row.comments_count_by_stage['fact']"
            }
        },
        {
            "label": "% премии",
            "prop": "bonus",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "width": 100
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}