<template>
  <el-dialog
    :visible.sync="show"
    width="50%"
    top="2vh"
    :close-on-click-modal="false"
    @opened="usersToAdd=[]"
  >
    <template slot="title">
      <div style="display: flex;">
        <div>Создание карт</div>
        <card-create-templates
          v-if="$canAndRulesPass(user, $permissions.CARD_CREATE_TEMPLATES.LIST)"
          style="margin-left: 50px"
          :card="$refs.cardEditForm ? $refs.cardEditForm.card : {}"
          :sections="$refs.cardEditForm ? $refs.cardEditForm.sections : []"
          :stages="$refs.cardEditForm ? $refs.cardEditForm.stages : {}"
          @template-selected="setSettingsFromTemplate"
        ></card-create-templates>
      </div>
    </template>

    <card-edit-form
      ref="cardEditForm"
    ></card-edit-form>

    <users-table
      :add-button="true"
      add-button-text="Добавить пользователей"
      add-button-type="primary"
      :delete-button="true"
      add-from-xls-button
      :users.sync="usersToAdd"
    ></users-table>


    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        :disabled="!usersToAdd.length"
        @click="preCreateCards"
      >
        Создать карты
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Container, Draggable} from 'vue-dndrop';
import StageItem from "@/components/cards/CardStagesBlock/StageItem";
import UsersTable from "@/components/user/UsersTable";
import CardCreateTemplates from "@/components/cards/CardCreateTemplates.vue";
import {mapGetters} from "vuex";
import PeriodSelector from "@/components/PeriodSelector.vue";
import CardEditForm from "@/components/cards/CardEditForm.vue";

export default {
  name: "card-edit-dialog",
  components: {CardEditForm, PeriodSelector, CardCreateTemplates, UsersTable, StageItem, Container, Draggable},

  props: {},

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      show: false,
      loading: false,

      usersToAdd: [],
    }
  },
  mounted() {
  },
  methods: {
    open() {
      this.usersToAdd = [];
      this.show = true;
    },
    close() {
      this.show = false;
    },

    setSettingsFromTemplate(template) {
      this.$refs.cardEditForm.setSettingsFromTemplate(template);
    },
    preCreateCards() {
      if (this.usersToAdd.length > 30) {
        this.$confirm('Из-за большого количества пользователей, создание карты будет поставлено в очередь. Сформированные карты появятся через некоторое время.', 'Внимание', {
          confirmButtonText: 'Да, создать',
          cancelButtonText: 'Нет',
          type: 'warning'
        }).then(() => {
          this.createCards(true);
        });
      } else {
        this.createCards();
      }
    },
    async createCards(to_queue = false) {
      let success = await this.$refs.cardEditForm.validate()
        .catch(() => {
          this.$nextTick(() => {
            let elementsInErrors = document.getElementsByClassName('is-error');
            if (elementsInErrors && elementsInErrors.length > 0) {
              elementsInErrors[0].scrollIntoView({
                behavior: "smooth",
                block: "center"
              });
            }
          });
        })

      if( !success ){
        return;
      }

      this.loading = true;
      let userIds = this.usersToAdd.map(user => user.id);
      let stagesArray = Object.keys(this.$refs.cardEditForm.stages).map(key => this.$refs.cardEditForm.stages[key]);
      this.$api.card.createCards({
        year: this.$refs.cardEditForm.card.year,
        period: this.$refs.cardEditForm.card.period,
        period_index: this.$refs.cardEditForm.card.period_index,
        stages: stagesArray.filter(stage => stage.enabled),
        sections: this.$refs.cardEditForm.sections,
        info: this.$refs.cardEditForm.card.info,
        user_ids: userIds,
        to_queue: to_queue,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
          this.close();
          this.$emit('card-saved');
        })
    },

  }
}
</script>

<style scoped lang="scss">
</style>